.add-shop-product-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 50px;
}

.list-product {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 13px;
    padding: 10px;
    margin-top: 30px;
}

.list-product-paper {
    width: 300px;
    height: 300px;
    display: grid;
    align-items: center;
    padding: 10px;
}

.card-body-product {
    padding-left: 10px;
}

.list-button {
    padding-left: 10px;
}