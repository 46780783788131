@import url("https://unpkg.com/@coreui/coreui/dist/css/coreui.min.css");

body {
  margin: 0 auto;
  font-family: 'DM Sans', 'Courier New';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
