.remove-model-container {
    width: 100%;
    display: grid;
    gap: 20px;
    justify-content: center;
}

.remove-model-container > div > h1 {
    text-align: center;
}

.input-line {
    display: flex;
    gap: 20px;
}

.remove-model-variant {
    padding: 10px;
}

.card-variant {
    width: 100%;
    display: grid;
}

.card-variant-header {
    display: grid;
    grid-template-columns: 5% 30% 30% 20% 20% 20%;
    word-break: break-all;
    align-items: center;
    grid-auto-rows: minmax(75px, auto);
    border-bottom: 0.15rem solid rgb(54 55 58);
    font-size: 1rem;
    justify-content: space-between;
}

.card-variant-element {
    display: grid;
    grid-template-columns: 10% 20% 10% 30% 15% 10%;
    word-break: break-all;
    align-items: center;
    grid-auto-rows: minmax(75px, auto);
    font-size: 1rem;
    justify-content: space-between;
    border-bottom: 0.15rem solid rgb(54 55 58 / 8%);
}

.card-variant-element > span {
    display: flex;
    justify-content: center;
}


.card-variant-header {
    border-bottom: 0.15rem solid rgb(54 55 58);
}

.card-variant-header > h4 {
    display: flex;
    justify-content: center;
}

.element:hover {
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    border: 1px solid rgba(211, 47, 47, 0.5);
    color: #d32f2f;
}


.card-variant-body > span {
    display: flex;
    justify-content: center;
    align-items: center;
}