.add-request-company-element {
    grid-auto-rows: minmax(35px, auto);
}

.add-request-company-header {
    background-color: #2a2b36;
}

.add-request-company-header > h4 {
    margin-block-end: 0;
    color: white;
}

.element:hover {
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #212529;
    background-color: rgba(255, 0, 0, 0.6);
    border: 0;
}

.element-selected {
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #212529;
    background-color: rgba(255, 0, 0, 0.6);
    border: 0;
}

.element-selected:hover {
    transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    color: #212529;
    background-color: rgba(255, 0, 0, 0.37);
    border: 0;
}

.add-request-element {
    grid-template-columns: 10% 30% 10% 10% 25% 10%;
}