.user-dashboard {
    position: absolute;
    width: 100%;
    min-height: 1000px;
    display: flex;
}

.user-dashboard-content {
    width: 100%;
}

.user-dashboard-body {

}