.sidebar-container {
    position: relative;
    max-height: calc(100vh - 75px);
    min-height: 100%;
    overflow: auto;
    width: 260px;
    opacity: 0.75;
    background-color: #2C2C2C;
}

.logo {
    width: 100%;
    padding: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #2C2C2C;
}

.logo > span {
    font-size: 25px;
    font-weight: 700;
    color: WHITE;
    margin: 5px;
}

.sidebar-brand {
    max-height: 56px;
    background-color: #22222f;
}

.sidebar-nav {
    background-color: #2C2C2C;
    color: white;
}

.active {
    background: linear-gradient(101deg, #386c5e 6.92%, #2c2c2c 97.07%);
}

.active > a {
    color: #ffffff;
}

.nav-button {
    height: 50px;
    display: flex;
    align-items: center;
    padding-left: 20px;
}

.nav-button > a {
    color: #b3b3b3;
    text-decoration: none;
    padding-left: 10px;
    font-size: 15px;
    font-weight: 600;
    padding-top: 3px;

}

