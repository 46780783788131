.product-cards {
    padding: 30px;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    gap: 36px;
}

.pagination {
    display: flex;
    justify-content: center;
}