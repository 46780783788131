.request-page-company-header {
    display: grid;
    grid-template-columns: 10% 15% 15% 20% 20%;
    word-break: break-all;
    align-items: center;
    grid-auto-rows: minmax(35px, auto);
    background-color: #2C2C2C;
    font-size: 1rem;
    justify-content: space-around;
}

.request-page-company-header > h6 {
    color: #ffffff;
    margin-bottom: 0;
}

.request-page-company-element {
    display: grid;
    grid-template-columns: 10% 15% 15% 20% 20%;
    word-break: break-all;
    align-items: center;
    grid-auto-rows: minmax(35px, auto);
    font-size: 1rem;
    justify-content: space-around;
}

.request-page-company-element:hover {
    background-color: rgba(2, 136, 209, 0.26);
}

.request-page-company-element-selected {
    display: grid;
    grid-template-columns: 10% 15% 15% 20% 20%;
    word-break: break-all;
    align-items: center;
    grid-auto-rows: minmax(35px, auto);
    font-size: 1rem;
    justify-content: space-around;
    background-color: rgba(2, 136, 209, 0.52);
}